(function ($, Drupal) {
  Drupal.behaviors.smMainMenu = {
    attach: function (context, settings) {
      /////////////////

      var $menuLinks = $(context).find('nav.menu-links');

      $menuLinks.once().each(function () {
        var $menu = $(this);
        var $contents = $('#block-sm-custom-blocks-main-sm-menu-contents');
        var $contentsContent = $contents.find('> .content');
        var $menuLinks = $menu.find('a');
        var isOpen = false;

        $contents.addClass('closed');

        /**
         * Opens the menu.
         * @param $el
         */
        function openMenu($el) {
          var contentTarget = $el.attr('data-content-target');
          isOpen = true;
          $el.addClass('open');
          $contents.attr('data-content-to-show', contentTarget);
          $contents.removeClass('closed');
          $contents.addClass('open');

          setTimeout(function () {
            $("#sm-custom-search-box").focus();
          }, 1000);
        }

        /**
         * Closes the menu.
         */
        function closeMenu() {
          isOpen = false;
          $contents.addClass('closing');
          $contents.removeClass('open');
          $menuLinks.removeClass('open');

          setTimeout(function () {
            $contents.addClass('closed');
            $contents.removeClass('closing');
          }, 1000);
        }

        /**
         * Closes the open menu and open a new one.
         * @param $el
         */
        function switchMenu($el) {
          $contents.addClass('closing');
          $contents.removeClass('open');
          $menuLinks.removeClass('open');

          setTimeout(function () {
            openMenu($el)
          }, 1000);
        }

        /**
         * If the menu is open and the used clicks
         * the greyed layer we close the menu.
         */
        $contents.once('close-on-overlay-click').each(function () {
          var $contents = $(this);

          $contents.click(function (e) {
            var $target = $(e.target);

            if ($target.is($contents) || $target.is($contentsContent)) {
              closeMenu();
            }
          });
        });

        /**
         * Handler for the main menu links.
         */
        $menuLinks.click(function (e) {
          var $el = $(this);
          e.preventDefault();
          var $target = $(e.target);

          if ($target.hasClass('open') || $target.parent().hasClass('open')) {
            closeMenu();
          } else {
            // Animations
            // ------------
            isOpen ? switchMenu($el) : openMenu($el);
          }

        })
      });

      /////////////////
    }
  };

})(jQuery, Drupal);